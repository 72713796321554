import { createSelector } from '@ngrx/store';
import { ActionTypes, TypeAction } from './app.actions';
import { IRootState } from './store/index';
import { ISupportContacts } from './core/services/supportContacts/interfaces';
import { IManagerProfileViewModel } from './core/services/managers/interfaces';
import { LoadingStatusEnum, ServiceStatusEnum } from './constants';

export interface ILoadingPage {
  status: LoadingStatusEnum;
  message: string;
}

export interface State {
  manager: IManagerProfileViewModel;
  pendingRequests: number;
  isSpinnerVisible: boolean;
  isNeedHelpVisible: boolean;
  supportContacts: ISupportContacts;
  isLogged: boolean;
  isSideBarOpen: boolean;
  scrollPosition: number;
  loadingPage: ILoadingPage;
  isInsiderTipViewed: boolean;
  isInsiderTipPopupOpen: boolean;
  serviceStatus: ServiceStatusEnum;
  isImpersonated: boolean;
}

const initialState: State = {
  manager: {
    firstName: '',
    lastName: '',
    email: '',
    employeeId: '',
    imageUrl: '',
    location: '',
    phone: '',
    title: '',
    welcomingMessage: ''
  },
  supportContacts: {
    mobile1StopPhoneNumber: ''
  },
  pendingRequests: 0,
  isSpinnerVisible: false,
  isNeedHelpVisible: false,
  isLogged: false,
  isSideBarOpen: false,
  scrollPosition: 0,
  loadingPage: {
    status: LoadingStatusEnum.hidden,
    message: '',
  },
  isInsiderTipViewed: false,
  isInsiderTipPopupOpen: false,
  serviceStatus: ServiceStatusEnum.initial,
  isImpersonated: false
};

export function appReducer(state: State = initialState, action: TypeAction): State {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_MANAGER: {
      return Object.assign({}, state, { manager: payload });
    }
    case ActionTypes.SET_SCROLL_POSITION: {
      return Object.assign({}, state, { scrollPosition: payload });
    }
    case ActionTypes.CLEAR_MANAGER: {
      const manager = Object.assign({}, {
        firstName: '',
        lastName: '',
        email: '',
        employeeId: '',
        imageUrl: '',
        location: '',
        phone: '',
        title: '',
        welcomingMessage: ''
      });

      return Object.assign({}, state, { manager });
    }
    case ActionTypes.REQUEST_START: {
      const addedRequest = state.pendingRequests + 1;

      return Object.assign({}, state, { pendingRequests: addedRequest });
    }
    case ActionTypes.REQUEST_END: {
      const removedRequest = state.pendingRequests - 1;

      return Object.assign({}, state, { pendingRequests: removedRequest });
    }
    case ActionTypes.SPINNER_SHOW: {
      return Object.assign({}, state, { isSpinnerVisible: true });
    }
    case ActionTypes.SPINNER_HIDE: {
      return Object.assign({}, state, { isSpinnerVisible: false });
    }
    case ActionTypes.TOGGLE_NEED_HELP: {
      return Object.assign({}, state, { isNeedHelpVisible: !state.isNeedHelpVisible });
    }
    case ActionTypes.SET_SUPPORT_CONTACTS: {
      const { supportContacts } = state;
      const result = Object.assign({}, supportContacts, payload);

      return Object.assign({}, state, { supportContacts: result });
    }
    case ActionTypes.RECRUIT_AUTH: {
      return Object.assign({}, state, { isLogged: payload });
    }
    case ActionTypes.TOGGLE_SIDE_BAR: {
      return Object.assign({}, state, { isSideBarOpen: payload });
    }
    case ActionTypes.SET_LOADING_STATE: {
      const { loadingPage } = state;
      const result = {
        ...loadingPage,
        ...payload
      };

      return Object.assign({}, state, { loadingPage: result });
    }
    case ActionTypes.SET_INSIDER_TIP_VISIBILITY_STATUS: {
      return Object.assign({}, state, { isInsiderTipViewed: payload });
    }
    case ActionTypes.SHOW_INSIDER_TIP: {
      return Object.assign({}, state, { isInsiderTipPopupOpen: true });
    }
    case ActionTypes.HIDE_INSIDER_TIP: {
      return Object.assign({}, state, {
        isInsiderTipViewed: true,
        isInsiderTipPopupOpen: false
      });
    }
    case ActionTypes.SET_SERVICE_STATUS: {
      return Object.assign({}, state, {
        serviceStatus: payload
      });
    }
    case ActionTypes.SET_IMPERSONATION: {
      return Object.assign({}, state, {
        isImpersonated: payload
      });
    }
    default: {
      return state;
    }
  }
}

export const getManager = createSelector(
  ({ application }: IRootState) => application,
  ({ manager }: State): IManagerProfileViewModel => manager
);

export const getHttpPending = createSelector(
  ({ application }: IRootState) => application,
  ({ pendingRequests }: State): boolean => Boolean(pendingRequests)
);

export const getSpinnerVisibilityStatus = createSelector(
  ({ application }: IRootState) => application,
  ({ isSpinnerVisible }: State) => isSpinnerVisible
);

export const getHelpVisibility = createSelector(
  ({ application }: IRootState) => application,
  ({ isNeedHelpVisible }: State) => isNeedHelpVisible
);

export const getSupportContacts = createSelector(
  ({ application }: IRootState) => application,
  ({ supportContacts }: State) => supportContacts
);

export const getIsLogged = createSelector(
  ({ application }: IRootState) => application,
  ({ isLogged }: State) => isLogged
);

export const getIsSideBarOpen = createSelector(
  ({ application }: IRootState) => application,
  ({ isSideBarOpen }: State) => isSideBarOpen
);

export const getScrollPosition = createSelector(
  ({ application }: IRootState) => application,
  ({ scrollPosition }: State) => scrollPosition
);

export const getIsLoadingPageVisible = createSelector(
  ({ application }: IRootState) => application,
  ({ loadingPage }: State): boolean => loadingPage.status !== LoadingStatusEnum.hidden
);

export const getIsLoadingComplete = createSelector(
  ({ application }: IRootState) => application,
  ({ loadingPage }: State): boolean => loadingPage.status === LoadingStatusEnum.loadingEnd
);

export const getLoadingMessage = createSelector(
  ({ application }: IRootState) => application,
  ({ loadingPage }: State) => loadingPage.message
);

export const getIsInsiderTipViewed = createSelector(
  ({ application }: IRootState) => application,
  ({ isInsiderTipViewed }: State) => isInsiderTipViewed
);

export const getIsInsiderTipPopupOpen = createSelector(
  ({ application }: IRootState) => application,
  ({ isInsiderTipPopupOpen }: State) => isInsiderTipPopupOpen
);

export const getServiceStatus = createSelector(
  ({ application }: IRootState) => application,
  ({ serviceStatus }: State): ServiceStatusEnum => serviceStatus
);

export const getIsImpersonated = createSelector(
  ({ application }: IRootState) => application,
  ({ isImpersonated }: State): boolean => isImpersonated
);
